<template>
  <div class="tasksID">

    <header-tasks-container name="Задачи"></header-tasks-container>

    <div id="tasksID_main_wrapper">

      <div id="tasksID_main">

        <div id="tasksID_main_content">

          <transition name="content_photo_move" appear>
            <div id="tasksID_main_title">
              {{ task_title }}
            </div>
          </transition>

          <transition name="content_photo_move" appear>
          <div id="tasksID_main_text" style="white-space: pre-wrap">
            {{ task_question }}
          </div>
          </transition>
          <transition name="content_photo_move" appear>
          <div id="tasksID_main_answer" v-on:click="is_visible_answer=!is_visible_answer">Ответ</div>
          </transition>

          <transition name="content_photo_move" appear>
          <div id="tasksID_main_text" v-if="is_visible_answer"
             style="white-space: pre-wrap">
          {{ task_answer }}
          </div>
          </transition>

        </div>


      </div>

    </div>

  </div>
</template>

<script>

import headerTasksContainer from "@/components/headerTasksContainer.vue";

export default {
  name: "TasksID",
  components: { headerTasksContainer },
  data() {
    return {
      is_visible_answer: false
    }
  },
  mounted() {
  },
  computed: {
    tasksId()  {
      return this.$route.params.id;
    },
    taskItems() {
      return this.$store.getters.getTaskItems
    },
    task_title : function () {
      return this.taskItems.find(item => item.itemID === this.tasksId).title
    },
    task_question : function () {
      return this.taskItems.find(item => item.itemID === this.tasksId).question
    },
    task_answer : function () {
      return this.taskItems.find(item => item.itemID === this.tasksId).answer
    },
  },
}
</script>

<style scoped>

.tasksID {
  width:                  100%;
  max-width:              100%;
  background-color:       #fdf5e6;
  font-family: sans-serif, Helvetica;
  margin-top:             var(--header-height);
}

#tasksID_main_wrapper {
  display:                grid;
  grid-template-columns:  1fr;
  max-width:              var(--width-wrapper-small);
  height:                 auto;
  min-height:             100vh;
  padding-left:           .5rem;
  padding-right:          .5rem;
  margin:                 1rem auto 1rem auto;
}

@media (min-width: 60rem) {
  #tasksID_main_wrapper {
    max-width:              var(--width-wrapper-middle);
  }
}
@media (min-width: 75rem) {
  #tasksID_main_wrapper {
    max-width:              var(--width-wrapper-large);
  }
}

#tasksID_main {
  display: 			          grid;
  grid-template-columns:  1fr;
  grid-gap:               1rem;
  max-width:              100%;
  min-width:              100%;
  margin-top:             2rem;
  margin-bottom:          2rem;
  justify-self:           left;
  grid-template-rows:     auto;
  font-weight:            400;
}

@media (min-width: 60rem) {
  #tasksID_main {
    grid-template-columns:  1fr;
    grid-template-rows:     auto;
    justify-content:        left;
    align-content:          flex-start;
    max-width:              var(--width-wrapper-middle);
  }
}

@media (min-width: 75rem) {
  #tasksID_main {
    grid-template-columns:  1fr;
    grid-template-rows:     auto;
    justify-content:        left;
    align-content:          flex-start;
    max-width:              var(--width-wrapper-large);
  }
}

#tasksID_main_title {
  color:                  #555;
  font-size:              var(--menu-item-font-size-20);
  margin-top:            -15px;
  padding-bottom:         1rem;
}

#tasksID_main_text {
  color:                  #555;
  font-size:              var(--menu-item-font-size-20);
}

#tasksID_main_answer {
  color:                  #555;
  font-size:              var(--menu-item-font-size-20);
  margin-top:             2rem;
  padding-bottom:         1rem;
  cursor: pointer;
}

@media (min-width: 60rem) {

  #tasksID_main_title {
    font-size:               calc(1.2*var(--menu-item-font-size-25));
  }

  #tasksID_main_text {
    font-size:              calc(1*var(--menu-item-font-size-25));
  }

  #tasksID_main_answer {
    font-size:               calc(1.2*var(--menu-item-font-size-25));
  }
}

.content_photo_move-enter-active {
  transition: all 1s ease-out;
}

.content_photo_move-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.content_photo_move-enter-from,
.content_photo_move-leave-to {
  opacity: 0;
}

.content_name_move-enter-active {
  transition: all 1s ease-out;
}

.content_name_move-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.content_name_move-enter-from,
.content_name_move-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

</style>